/* You can add global styles to this file, and also import other style files */

@use "se-fe-styles/styles/v2/colors.variables";
@use "se-fe-styles/styles/fonts.variables";

.line-break {
  white-space: pre-wrap;
}

th.se-fe-header-cell {
  cursor: pointer;
}

td.se-fe-cell > a {
  text-decoration: none;
  color: #008cc3;
}

.se-fe-spinner--900 {
  height: 68px;
}

.se-fe-spinner--discountDataLoading {
  margin-top: 24px;
}

.discount-value-radios {
  .se-fe-form-field-option__label {
    display: flex;
  }
}

.element-container {
  margin-top: 12px;
}

.select-sale-item {
  &__error {
    box-sizing: border-box;
    display: block;
    color: #b42846;
    font-size: calc(var(--se-rem, 1rem)* .8571428571);
    margin-top: 4px;
  }
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
